import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "page",
  pluralName: "pages",
  pathName: "pages",
  endpoint: "/admin/pages",
  serverName: "page",
  serverPluralName: "pages",
  displayName: { en: "Pages", ar: "الصفحات" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  authorizedRoles: {},
  isFullWidth: true,
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "title",
      label: { en: "Title", ar: "الاسم" },
      accessor: "title",
      smCol: 6,
      mdCol: 6,
      lgCol: 6,
      type: "string",
      inList: true,
      required: true,
      localized: true,
      locales: [
        {
          locale: "en",
          labelSuffix: {
            en: "english",
            ar: "الإنجليزية",
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
        {
          locale: "ar",
          labelSuffix: {
            en: "arabic",
            ar: "عربى",
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
      ],
    },
    {
      identifier: "description",
      label: { en: "Description", ar: "الوصف" },
      accessor: "description",
      type: "editor",
      smCol: 12,
      mdCol: 12,
      lgCol: 12,
      editorConfig: {
        imageUploadType: "external",
        endpoint: "admin/images",
        width: "100%",
      },
      required: true,
      localized: true,
      locales: [
        {
          locale: "en",
          labelSuffix: {
            en: "english",
            ar: "الإنجليزية",
          },
          required: true,
          usedToShow: true,
          inList: false,
        },
        {
          locale: "ar",
          labelSuffix: {
            en: "arabic",
            ar: "عربى",
          },
          required: true,
          usedToShow: true,
          inList: false,
        },
      ],
    },
    {
      identifier: "category_id",
      label: { en: "Parent Category", ar: "الفئة الرئيسية" },
      formAccessor: (dataObject) => (dataObject.category ? dataObject.category.id : null),
      accessor: (dataObject) => (dataObject.category ? dataObject.category.title : "-"),
      type: "reference",
      inList: true,
      isSearchable: true,
      required: true,
      smCol: 12,
      mdCol: 12,
      lgCol: 12,
      optionsEntity: {
        name: "category",
        labelField: (dataObject) => `${dataObject.identified_title}`,
        valueField: "id",
      },
    },
  ],
  filters: [
    {
      label: { en: "Category", ar: "الفئة" },
      name: "category_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        isSearchable: true,
        optionsEntity: {
          name: "category",
          labelField: "title",
          valueField: "id",
        },
      },
    },
  ],
});
