import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "menuItem",
  pluralName: "menuItems",
  pathName: "menuItems",
  endpoint: "/admin/menu_items",
  serverName: "menu_item",
  serverPluralName: "menu_items",
  displayName: { en: "Menu Items", ar: "القوائم" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  requestParams: {
    list: {
      includes: "parent",
    },
  },
  fields: [
    {
      identifier: "id",
      label: { en: "ID", ar: "الرقم التسلسلي" },
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "الاسم" },
      accessor: "name",
      type: "string",
      inList: true,
      required: true,
      isSearchable: true,
      localized: true,
      locales: [
        {
          locale: "en",
          labelSuffix: {
            en: "english",
            ar: "الإنجليزية",
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
        {
          locale: "ar",
          labelSuffix: {
            en: "arabic",
            ar: "عربى",
          },
          required: true,
          usedToShow: true,
          inList: true,
        },
      ],
    },
    {
      identifier: "menu_type",
      label: { en: "Menu Type", ar: "نوع القائمه" },
      accessor: "menu_type",
      type: "select",
      dependee: true,
      required: true,
      options: [
        {
          value: 0,
          label: { en: "Grandparent", ar: "قائمه رئيسيه" },
        },
        {
          value: 1,
          label: { en: "Parent", ar: "قائمه فرعيه" },
        },
        {
          value: 2,
          label: { en: "Child", ar: "قائمه ثانويه" },
        },
      ],
    },
    {
      identifier: "parent_id",
      label: { en: "Parent Menu Item", ar: "القائمه الاعلي" },
      formAccessor: (dataObject) => (dataObject.parent ? dataObject.parent.id : null),
      accessor: (dataObject) => (dataObject.parent ? dataObject.parent.name : "-"),
      type: "reference",
      dependsOn: "menu_type",
      dependsOnValue: [1, 2],
      inList: true,
      isSearchable: true,
      optionsEntity: {
        name: "menuItem",
        labelField: (dataObject) => `${dataObject.identified_name}`,
        valueField: "id",
      },
    },
    {
      identifier: "link",
      label: { en: "Link", ar: "الرابط" },
      accessor: "link",
      type: "string",
      required: (action, dataObject, location, state) => state.menu_type === 2,
      dependsOn: "menu_type",
      dependsOnValue: [1, 2],
    },
    {
      identifier: "link_type",
      label: { en: "Link Opened in Same Tab", ar: "فتح الرابط فى نفس الصفحه" },
      formAccessor: "link_type",
      accessor: (dataObject, language) => (dataObject.menu_type === 0 ? "-" : dataObject.link_type),
      type: "toggle",
      defaultValue: "external",
      toggleConfig: {
        on: "internal",
        off: "external",
      },
      inList: true,
      dependsOn: "menu_type",
      dependsOnValue: [1, 2],
    },
    {
      identifier: "index",
      label: { en: "Index", ar: "الترتيب" },
      accessor: "index",
      type: "number",
      required: true,
      defaultValue: 1,
      min: 1,
      dependsOn: "menu_type",
      dependsOnValue: 0,
    },
  ],
  filters: [
    {
      label: { en: "Parent Menu Item", ar: "القائمه الرئيسية" },
      name: "parent_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        isSearchable: true,
        optionsEntity: {
          name: "menuItem",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
});
